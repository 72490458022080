import { format } from 'date-fns'

import { toPrice } from '@/util/helpers'

export function formatPrice (value) {
  return `ARS ${toPrice(value)}`
}

export function formatDate (value) {
  return value ? format(new Date(value), 'dd/MM/yyyy HH:mm') : '-'
}

export function formatShortDate (value) {
  return value ? format(new Date(value), 'dd/MM/yyyy') : '-'
}

export function formatCuit (number) {
  if (number) {
    const firstNumbers = number.substr(0, 2)
    const dniNumbers = number.substr(2, 8)
    const lastNumber = number.substr(10, 11)

    return `${firstNumbers}-${dniNumbers}-${lastNumber}`
  }
}
